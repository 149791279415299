// src/pages/UserLib.js
import React, { useState } from 'react';
import Header from '../../containers/Header';
import Footer from '../../containers/Footer';
import SearchBar from '../../components/SearchBar';
import BookList from '../../components/BookList';
import './assets/style.scss'; // Pour le style si nécessaire

function UserLib() {
    const [searchResults] = useState([]);

    const handleSearch = (query) => {
        // Logique de recherche à implémenter plus tard
        console.log('Searching for:', query);
    };

    return (
        <>
            <Header />
            <div className="user-lib-container">
                <SearchBar onSearch={handleSearch} />
                <BookList books={searchResults} />
            </div>
<a target="_blank" href="https://www.amazon.fr/b?_encoding=UTF8&tag=nexuslibri-21&linkCode=ur2&linkId=a90e75681d234f98009704b4c07d999e&camp=1642&creative=6746&node=301061">Plus grand Best-Sellers !</a>
            <Footer />
        </>
    );
}

export default UserLib;
