import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../containers/Header';
import Footer from '../../containers/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/style.scss';
import CollectionLikeBookView from '../../components/CollectionLikeBookView';
import AdsenseExample from '../../adsComponents/AdsenseExample';
const API_URL = process.env.REACT_APP_API_URL;

function PublicCollectionView() {
  const { collectionId } = useParams();
  const [books, setBooks] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [viewMode, setViewMode] = useState('card'); // default to card view

  useEffect(() => {
    const fetchCollectionDetails = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_URL}/public-collections/${collectionId}`);
        if (!response.ok) {
          const errorMessage = await response.json();
          throw new Error(`Failed to fetch collection details: ${errorMessage.error.message}`);
        }

        const collectionData = await response.json();
        setBooks(collectionData.books);
        setLoading(false);

        // Increment views
        await fetch(`${API_URL}/public-collections/${collectionId}/view`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        });
      } catch (err) {
        console.error(err);
        setError('La collection recherchée n\'existe pas ou n\'est plus disponible.');
        setLoading(false);
      }
    };

    fetchCollectionDetails();
  }, [collectionId]);

  const handleBookClick = () => {
    document.dispatchEvent(new Event('showConnectionModal'));
  };

  if (loading) {
    return (
      <div>
        <Header />
        <div className="container collection-details">
          <h2>Chargement en cours...</h2>
        </div>
        <Footer />
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <Header />
        <div className="container collection-details">
          <h2>{error}</h2>
        </div>
        <Footer />
      </div>
    );
  }
  
  return (
    <div>
      <Header />
      <div className="container collection-details">
        <h2>Collection Publique</h2>
        <div className="row">
          <div className="col-md-9">
            {viewMode === 'card' ? (
              <div className="row row-cols-1 row-cols-md-3 g-4 justify-content-center">
                {books.map((book) => (
                  <div key={book.bookId} className="col mb-4">
                    <div onClick={handleBookClick} className="card h-100 shadow-sm book-item">
                      <div className="bg-image" style={{ backgroundImage: `url(${book.imageLinks.small})` }}></div>
                      <div className="card-body">
                        <h4 className="card-title">{book.title}</h4>
                        <p className="card-text">{book.authors.join(', ')}</p>
                        <p className="card-text"><small className="text-muted">{book.categories.join(', ')}</small></p>
                        <p className="card-text"><small className="text-muted">{book.publishedDate}</small></p>
                        <p className="card-text">Note: {book.note}</p>
                      </div>
                      <div className="note-overlay">
                        <p>Note: {book.note}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="list-view">
                <ul className="list-group">
                  {books.map((book) => (
                    <li key={book.bookId} className="list-group-item d-flex justify-content-between align-items-center" onClick={handleBookClick}>
                      <div>
                        <h4>{book.title}</h4>
                        <p>{book.authors.join(', ')}</p>
                        <p>{book.categories.join(', ')}</p>
                        <p>{book.publishedDate}</p>
                        <p>Note: {book.note}</p>
                      </div>
                      <div>
                        <button className="btn btn-primary me-2">View</button>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="col-md-3">
            <div className="toggle-view">
              <button className="btn btn-secondary" onClick={() => setViewMode(viewMode === 'card' ? 'list' : 'card')}>
                {viewMode === 'card' ? 'Afficher en Liste' : 'Afficher en Cartes'}
              </button>
            </div>
            <CollectionLikeBookView collectionId={collectionId} />
          </div>
          <AdsenseExample />
        </div>
      </div>
      <Footer />
    </div>
  );  
}

export default PublicCollectionView;
