import React from 'react';
import { Adsense } from '@ctrl/react-adsense';
import './assets/style.scss';

function AdsenseExample() {
    return (
        <Adsense
            className='ExampleAdSlot'
            client="ca-pub-6263985288461393" // Remplacez par votre propre ID client
            slot="9280596519" // Remplacez par votre propre ID de slot
            style={{ display: 'block' }}
            format="auto"
            data-full-width-responsive="true"
            responsive="true"
        />
    );
}

export default AdsenseExample;
