import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../containers/Header';
import Footer from '../../containers/Footer';
import './assets/style.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

const API_URL = process.env.REACT_APP_API_URL;

const UserProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    currentPassword: '',
    newPassword: '',
    repeatNewPassword: ''
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const [passwordVisible, setPasswordVisible] = useState({
    currentPassword: false,
    currentPasswordEmail: false,
    newPassword: false,
    repeatNewPassword: false
  });

  const { username, email, currentPassword, newPassword, repeatNewPassword } = formData;

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('libriToken');
        if (!token) throw new Error('No token found');

        const response = await fetch(`${API_URL}/user/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) throw new Error('Failed to fetch user data');

        const data = await response.json();
        setFormData({
          ...formData,
          username: data.username,
          email: data.email
        });
      } catch (err) {
        console.error('Error fetching user data:', err);
        setError(err.message);
      }
    };

    fetchUserData();
  }, [id]);

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const togglePasswordVisibility = (field) => {
    setPasswordVisible({ ...passwordVisible, [field]: !passwordVisible[field] });
  };

  const onSubmitUsername = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    try {
      const token = localStorage.getItem('libriToken');
      if (!token) throw new Error('No token found');

      const response = await fetch(`${API_URL}/user/${id}/username`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ username, currentPassword })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Echec lors du changement du nom d\'utilisateur');
      }

      setSuccess('Succès lors du changement du nom d\'utilisateur.');
    } catch (err) {
      console.error('Erreur lors du changement du nom d\'utilisateur:', err);
      setError(err.message);
    }
  };

  const onSubmitEmail = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    try {
      const token = localStorage.getItem('libriToken');
      if (!token) throw new Error('No token found');

      const response = await fetch(`${API_URL}/user/${id}/email`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ email, currentPassword })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Echec lors du changement d\'email');
      }

      setSuccess('Email mis à jour avec succès !');
    } catch (err) {
      console.error('Erreur lors du changement d\'email:', err);
      setError(err.message);
    }
  };

  const onSubmitPassword = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    if (newPassword !== repeatNewPassword) {
      setError('Les mots de passe ne sont pas les mêmes !');
      return;
    }

    try {
      const token = localStorage.getItem('libriToken');
      if (!token) throw new Error('No token found');

      const response = await fetch(`${API_URL}/user/${id}/password`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ newPassword })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Erreur lors de la mise à jour du mot de passe');
      }

      setSuccess('Mot de passe mis à jour avec succès !');
    } catch (err) {
      console.error('Erreur lors de la mise à jour du mot de passe:', err);
      setError(err.message);
    }
  };

  return (
    <div>
      <Header />
      <div className="container mt-2">
        <div className="container">
          <div className="row align-items-center mb-4">
            <div className="col-auto">
              <button className="btn btn-link back-to-menu" onClick={() => navigate(-1)}>
                &larr; Retour en arrière
              </button>
            </div>
            <div className="col">
              <h2 className="mb-0">Votre profil</h2>
            </div>
          </div>
        </div>
        {error && <div className="alert alert-danger">{error}</div>}
        {success && <div className="alert alert-success">{success}</div>}
  
        <form onSubmit={onSubmitUsername}>
          <div className="mb-3">
            <label htmlFor="username" className="form-label">Nom d'utilisateur</label>
            <input
              type="text"
              className="form-control"
              id="username"
              name="username"
              value={username}
              onChange={onChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="currentPassword" className="form-label">Votre mot de passe</label>
            <div className="input-group">
              <input
                type={passwordVisible.currentPassword ? "text" : "password"}
                className="form-control"
                id="currentPassword"
                name="currentPassword"
                value={currentPassword}
                onChange={onChange}
              />
              <span className="input-group-text-emot" onClick={() => togglePasswordVisibility('currentPassword')}>
                {passwordVisible.currentPassword ? '👀' : '🙈'}
              </span>
            </div>
          </div>
          <button type="submit" className="btn btn-primary">Mettre à jour le nom d'utilisateur</button>
        </form>
  
        <form onSubmit={onSubmitEmail} className="mt-4">
          <div className="mb-3">
            <label htmlFor="email" className="form-label">Email</label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              value={email}
              onChange={onChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="currentPasswordEmail" className="form-label">Votre mot de passe</label>
            <div className="input-group">
              <input
                type={passwordVisible.currentPasswordEmail ? "text" : "password"}
                className="form-control"
                id="currentPasswordEmail"
                name="currentPassword"
                value={currentPassword}
                onChange={onChange}
              />
              <span className="input-group-text-emot" onClick={() => togglePasswordVisibility('currentPasswordEmail')}>
                {passwordVisible.currentPasswordEmail ? '👀' : '🙈'}
              </span>
            </div>
          </div>
          <button type="submit" className="btn btn-primary">Mettre à jour l'email</button>
        </form>
  
        <form onSubmit={onSubmitPassword} className="mt-4">
          <div className="mb-3">
            <label htmlFor="newPassword" className="form-label">Nouveau mot de passe</label>
            <div className="input-group">
              <input
                type={passwordVisible.newPassword ? "text" : "password"}
                className="form-control"
                id="newPassword"
                name="newPassword"
                value={newPassword}
                onChange={onChange}
              />
              <span className="input-group-text-emot" onClick={() => togglePasswordVisibility('newPassword')}>
                {passwordVisible.newPassword ? '👀' : '🙈'}
              </span>
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="repeatNewPassword" className="form-label">Répéter le mot de passe</label>
            <div className="input-group">
              <input
                type={passwordVisible.repeatNewPassword ? "text" : "password"}
                className="form-control"
                id="repeatNewPassword"
                name="repeatNewPassword"
                value={repeatNewPassword}
                onChange={onChange}
              />
              <span className="input-group-text-emot" onClick={() => togglePasswordVisibility('repeatNewPassword')}>
                {passwordVisible.repeatNewPassword ? '👀' : '🙈'}
              </span>
            </div>
          </div>
          <button type="submit" className="btn btn-primary">Mettre à jour le mot de passe</button>
        </form>
      </div>
      <Footer />
    </div>
  ); 
};

export default UserProfile;
