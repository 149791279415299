import React from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/style.scss';

const CollectionCard = ({ collection, id, friendId, handleDeleteCollection }) => {
  const navigate = useNavigate();

  const handleCollectionClick = (collectionId) => {
    if (friendId) {
      navigate(`/user/${id}/friend/${friendId}/public-collections/${collectionId}`);
    } else {
      navigate(`/user/${id}/collections/${collectionId}`);
    }
  };

  const onDeleteClick = (e) => {
    e.stopPropagation(); // Empêche la propagation de l'événement de clic parent
    if (handleDeleteCollection) {
      handleDeleteCollection(e, collection._id);
    }
  };

  return (
    <div
      className="col-md-3"
      onClick={() => handleCollectionClick(collection._id)}
      style={{ cursor: 'pointer' }}
    >
      <div className="collection-card">
        <div className="card-header">
          <h4 className="collection-name">{collection.name}</h4>
        </div>
        <div className="card-body">
          <div className="thumbnails">
            {collection.books.slice(0, 4).map((book) => (
              <div key={book.bookId} className="thumbnail-container">
                <img
                  src={book.imageLinks?.thumbnail || 'default-thumbnail.jpg'}
                  alt={book.title}
                  className="thumbnail img-fluid"
                />
              </div>
            ))}
          </div>
          <div className="book-info">
            {collection.books.slice(0, 4).map((book) => (
              <div key={book.bookId} className='book-info'>
                <h5>{book.title}</h5>
              </div>
            ))}
            {collection.books.length > 4 && (
              <div className="bookmarked-book-info">
                <h5>Et {collection.books.length - 4} de plus...</h5>
              </div>
            )}
          </div>
        </div>
        {handleDeleteCollection && (
          <div className="card-footer">
            <button
              className="btn btn-danger"
              onClick={onDeleteClick}
            >
              Supprimer
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CollectionCard;
