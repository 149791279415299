import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/style.scss'; // Assurez-vous d'ajouter le fichier de style si nécessaire
const API_URL = process.env.REACT_APP_API_URL;

function BookmarkedCollections() {
  const { id } = useParams();
  const [bookmarkedCollections, setBookmarkedCollections] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBookmarkedCollections = async () => {
      const token = localStorage.getItem('libriToken');
      if (!token) {
        alert('Authentication token not found. Please log in.');
        return;
      }

      try {
        const response = await fetch(`${API_URL}/user/${id}/bookmarked-collections`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          const errorMessage = await response.json();
          throw new Error(`Failed to fetch bookmarked collections: ${errorMessage.error.message}`);
        }

        const data = await response.json();
        setBookmarkedCollections(data);
      } catch (err) {
        console.error(err);
        setError(`Error fetching bookmarked collections: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchBookmarkedCollections();
  }, [id]);

  const truncateTitle = (title, maxLength = 45) => {
    if (title.length <= maxLength) {
      return title;
    }
    return `${title.slice(0, maxLength)}...`;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="bookmarked-collections">
      <h3>Bookmarked Collections</h3>
      <div className="container collections-list">
        {error && <p className="error">{error}</p>}
        <div className="row">
          {bookmarkedCollections.map((bookmark) => {
            const { collectionId, friendId, collectionName, books } = bookmark;
            return (
              <div key={collectionId} className="col-md-3" style={{ cursor: 'pointer' }}>
                <Link to={`/user/${id}/friend/${friendId}/public-collections/${collectionId}`} className="collection-link">
                  <div className="collection-card">
                    <h4 className="collection-name">{collectionName}</h4>
                    <div className="thumbnails">
                      {books.slice(0, 4).map((book) => (
                        <div key={book.bookId} className="thumbnail-container">
                          <img
                            src={book.imageLinks?.thumbnail || 'default-thumbnail.jpg'}
                            alt={book.title}
                            className="thumbnail img-fluid"
                          />
                        </div>
                      ))}
                    </div>
                    <div className="bookmarked-book-info">
                      {books.slice(0, 4).map((book) => (
                        <div key={book.bookId} className='bookmarked-book-info'>
                          <h5>{truncateTitle(book.title)}</h5>
                        </div>
                      ))}
                      {books.length > 4 && (
                        <div className="bookmarked-book-info">
                          <h5>Et {books.length - 4} de plus...</h5>
                        </div>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default BookmarkedCollections;
