import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Header from '../../containers/Header';
import Footer from '../../containers/Footer';
import CollectionStats from '../../components/CollectionStats'; 
import BookSearch from '../../components/SearchBar';
import ShareCollectionLink from '../../components/ShareCollectionLink'; // Import the component
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/style.scss';

const API_URL = process.env.REACT_APP_API_URL;

function CollectionDetails() {
  const { id, collectionId } = useParams();
  const [books, setBooks] = useState([]);
  const [error, setError] = useState('');
  const [isPublic, setIsPublic] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [viewMode, setViewMode] = useState('card'); // default to card view
  const [loading, setLoading] = useState(true);
  const [likes, setLikes] = useState(0);
  const [bookmarks, setBookmarks] = useState(0);
  const [views, setViews] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCollectionDetails = async () => {
      setLoading(true);
      const token = localStorage.getItem('libriToken');
      if (!token) {
        alert('Authentication token not found. Please log in.');
        return;
      }

      try {
        const response = await fetch(`${API_URL}/user/${id}/collections/${collectionId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          const errorMessage = await response.json();
          throw new Error(`Failed to fetch collection details: ${errorMessage.error.message}`);
        }

        const collectionData = await response.json();
        setBooks(collectionData.books);
        setIsPublic(collectionData.isPublic);
        setLikes(collectionData.likes);
        setBookmarks(collectionData.bookmarks);
        setViews(collectionData.views);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setError(`Error fetching collection details: ${err.message}`);
        setLoading(false);
      }
    };

    fetchCollectionDetails();
  }, [id, collectionId]);

  const handleDeleteBook = async (e, bookId) => {
    e.stopPropagation(); // Prevent triggering the Link navigation

    const token = localStorage.getItem('libriToken');
    if (!token) {
      alert('Authentication token not found. Please log in.');
      return;
    }

    try {
      const response = await fetch(`${API_URL}/user/${id}/collections/${collectionId}/books/${bookId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorMessage = await response.json();
        throw new Error(errorMessage.message || 'Failed to delete book from collection');
      }

      // Filter the books to remove the one that was deleted
      const updatedBooks = books.filter((book) => book.bookId !== bookId);
      setBooks(updatedBooks);
      alert('Book removed from collection successfully!');
    } catch (err) {
      console.error(err);
      alert(`Error deleting book: ${err.message}`);
    }
  };

  if (loading) {
    return (
      <div>
        <Header />
        <div className="container collection-details">
          <h2>Loading...</h2>
        </div>
        <Footer />
      </div>
    );
  }

  const handleOpenConfirmModal = () => {
    setShowConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const toggleCollectionVisibility = async () => {
    const token = localStorage.getItem('libriToken');
    if (!token) {
      alert('Authentication token not found. Please log in.');
      return;
    }

    try {
      const response = await fetch(`${API_URL}/user/${id}/collections/${collectionId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ isPublic: !isPublic })
      });

      if (!response.ok) {
        throw new Error('Failed to update collection visibility');
      }

      setIsPublic(!isPublic);  // Update the local state to reflect the change
      handleCloseConfirmModal();  // Close the confirmation modal
      alert(`Collection is now ${isPublic ? 'private' : 'public'}.`);
    } catch (error) {
      console.error('Error changing collection visibility:', error);
      alert('Failed to change collection visibility.');
    }
  };

  const limitText = (text, limit) => {
    if (text.length > limit) {
      return text.substring(0, limit) + '...';
    }
    return text;
  };

  return (
    <div>
      <Header />
      <div className="container collection-details">
        <div className="row">
          <div className="col-md-10">
            <h2>Collection</h2>
            <BookSearch isInCollectionDetail={true} />
            {isPublic && <ShareCollectionLink />} {/* Render ShareCollectionLink only if the collection is public */}
            {error && <p className="error">{error}</p>}
            {viewMode === 'card' ? (
              <div className="row row-cols-1 row-cols-md-4 g-4 justify-content-center">
                {books.map((book) => (
                  <div key={book.bookId} className="col mb-4">
                    <div className="card h-100 shadow-sm" onClick={() => navigate(`/user/${id}/book/${book.bookId}`)} style={{ cursor: 'pointer' }}>
                      <div className="card-header">
                        <h4 className="card-title">{limitText(book.title, 20)}</h4>
                      </div>
                      <div className="card-body">
                        <div className="bg-image" style={{ backgroundImage: `url(${book.imageLinks.small})` }}></div>
                        <p className="card-text">{limitText(book.authors.join(', '), 30)}</p>
                        <p className="card-text">
                          <small className="text-muted">{limitText(book.categories.join(', '), 30)}</small>
                        </p>
                        <p className="card-text">
                          <small className="text-muted">{book.publishedDate}</small>
                        </p>
                      </div>
                      <div className="card-footer">
                        <p>Note: {book.note}</p>
                        <button className="btn btn-danger" onClick={(e) => handleDeleteBook(e, book.bookId)}>&times; Supprimer</button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="list-view">
                <ul className="list-group">
                  {books.map((book) => (
                    <li key={book.bookId} className="list-group-item d-flex justify-content-between align-items-center">
                      <div>
                        <h4>{book.title}</h4>
                        <p>{book.authors.join(', ')}</p>
                        <p>{limitText(book.categories.join(', '), 50)}</p>
                        <p>{book.publishedDate}</p>
                        <p>Note: {book.note}</p>
                      </div>
                      <div>
                        <img src={book.imageLinks.thumbnail} alt={book.title}></img>
                        <Link to={`/user/${id}/book/${book.bookId}`} className="btn btn-primary me-2">Voir livre</Link>
                        <button
                          className="btn btn-danger"
                          onClick={(e) => handleDeleteBook(e, book.bookId)}
                        >
                          Supprimer
                        </button>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="col-md-2">
            <div className="toggle-view mb-3">
              <button className="btn btn-secondary" onClick={() => setViewMode(viewMode === 'card' ? 'list' : 'card')}>
                {viewMode === 'card' ? 'Afficher en Liste' : 'Afficher en Cartes'}
              </button>
            </div>
            <div className="toggle-visibility mb-3">
              <button className="btn btn-info" onClick={handleOpenConfirmModal}>
                {isPublic ? 'Rendre privé' : 'Rendre public'}
              </button>
              {showConfirmModal && (
                <div className="confirm-modal">
                  <br />
                  <button className="btn btn-danger" onClick={toggleCollectionVisibility}>
                    Confirmer
                  </button>
                  <br />
                  <button className="btn btn-secondary" onClick={handleCloseConfirmModal}>
                    Annuler
                  </button>
                  <br />
                </div>
              )}
            </div>
            {isPublic && <CollectionStats likes={likes} bookmarks={bookmarks} views={views} />}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CollectionDetails;
