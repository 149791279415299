import React from 'react';
import DOMPurify from 'dompurify';

const BookInfo = ({ book }) => {
  const sanitizedDescription = book ? DOMPurify.sanitize(book.volumeInfo.description) : '';

  return (
    <div>
      <h2>{book.volumeInfo.title}</h2>
      <p><strong>Auteur/s:</strong> {book.volumeInfo.authors?.join(', ')}</p>
      <p><strong>Editeur:</strong> {book.volumeInfo.publisher}</p>
      <p><strong>Date de publication:</strong> {book.volumeInfo.publishedDate}</p>
      <div dangerouslySetInnerHTML={{ __html: sanitizedDescription }} />
      <p><strong>Nombre de pages:</strong> {book.volumeInfo.pageCount}</p>
      <p><strong>Catégorie/s:</strong> {book.volumeInfo.categories?.join(', ')}</p>
      <p><strong>Langue:</strong> {book.volumeInfo.language}</p>
    </div>
  );
};

export default BookInfo;