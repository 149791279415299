import React, { useState } from 'react';
import { Modal, Button, Form, Tab, Tabs } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './assets/style.scss';

const API_URL = process.env.REACT_APP_API_URL;

function ModalConnect({ showModal, setShowModal }) {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    repeatPassword: ''
  });

  const [error, setError] = useState('');  // State for login errors
  const [registerError, setRegisterError] = useState('');  // State for registration errors
  const [showPassword, setShowPassword] = useState(false); // State for password visibility
  const [showRepeatPassword, setShowRepeatPassword] = useState(false); // State for repeat password visibility

  const { username, email, password, repeatPassword } = formData;

  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmitLogin = async (e) => {
    e.preventDefault();
    setError('');  // Clear previous error
    try {
      const response = await fetch(`${API_URL}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Connexion échouée');
      }

      const data = await response.json();
      localStorage.setItem('libriToken', data.token);

      const decodedToken = parseJwt(data.token);
      const userId = decodedToken.user.id;

      navigate(`/user/${userId}`); 

    } catch (err) {
      setError(err.message);
      console.error(err);
    }
  };

  const onSubmitRegister = async (e) => {
    e.preventDefault();
    setRegisterError('');  // Clear previous error
    try {
      if (!username || !email || !password || password !== repeatPassword) {
        throw new Error('Merci de remplir tous les champs.');
      }

      const response = await fetch(`${API_URL}/auth/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username, email, password })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `Erreur lors de l'inscription.`);
      }

      const data = await response.json();
      localStorage.setItem('libriToken', data.token);

      const decodedToken = parseJwt(data.token);
      const userId = decodedToken.user.id;

      navigate(`/user/${userId}`);

    } catch (err) {
      setRegisterError(err.message);
      console.error(err);
    }
  };

  // Function to parse JWT token to get payload
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Connexion / Inscription</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs defaultActiveKey="login" id="auth-tab">
          <Tab eventKey="login" title="Connexion">
            <Form onSubmit={onSubmitLogin}>
              <Form.Group controlId="login-email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" value={email} name="email" onChange={onChange} />
              </Form.Group>

              <Form.Group controlId="login-password">
                <Form.Label>Mot de passe</Form.Label>
                <div className="password-wrapper">
                  <Form.Control 
                    type={showPassword ? "text" : "password"} 
                    value={password} 
                    name="password" 
                    onChange={onChange} 
                  />
                  <span 
                    className="toggle-password" 
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? '👀' : '🙈'}
                  </span>
                </div>
              </Form.Group>

              <Form.Group className="d-flex justify-content-between">
                <a href="#!">Mot de passe oublié ?</a>
              </Form.Group>

              <Button variant="primary" type="submit" className="btn-block">Connexion</Button>

              {error && <div className="alert alert-danger mt-3">{error}</div>}  {/* Display login error */}

              <div className="text-center">
                <p>Pas un membre ? <a href="#!" onClick={() => setShowModal(false)}>Inscription</a></p>
              </div>
            </Form>
          </Tab>
          <Tab eventKey="register" title="Inscription">
            <Form onSubmit={onSubmitRegister}>
              <Form.Group controlId="register-username">
                <Form.Label>Nom d'utilisateur</Form.Label>
                <Form.Control type="text" value={username} name="username" onChange={onChange} />
              </Form.Group>

              <Form.Group controlId="register-email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" value={email} name="email" onChange={onChange} />
              </Form.Group>

              <Form.Group controlId="register-password">
                <Form.Label>Mot de passe</Form.Label>
                <div className="password-wrapper">
                  <Form.Control 
                    type={showPassword ? "text" : "password"} 
                    value={password} 
                    name="password" 
                    onChange={onChange} 
                  />
                  <span 
                    className="toggle-password" 
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? '👀' : '🙈'}
                  </span>
                </div>
              </Form.Group>

              <Form.Group controlId="repeatPassword">
                <Form.Label>Répétez votre mot de passe</Form.Label>
                <div className="password-wrapper">
                  <Form.Control 
                    type={showRepeatPassword ? "text" : "password"} 
                    value={repeatPassword} 
                    name="repeatPassword" 
                    onChange={onChange} 
                  />
                  <span 
                    className="toggle-password" 
                    onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                  >
                    {showRepeatPassword ? '👀' : '🙈'}
                  </span>
                </div>
              </Form.Group>

              <Button variant="primary" type="submit" className="btn-block">Inscription</Button>

              {registerError && <div className="alert alert-danger mt-3">{registerError}</div>}  {/* Display registration error */}
            </Form>
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
}

export default ModalConnect;
