import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Header from '../../containers/Header';
import Footer from '../../containers/Footer';

const API_URL = process.env.REACT_APP_API_URL;

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

function FriendsList() {
  const { id } = useParams();
  const navigate = useNavigate(); 
  const [friends, setFriends] = useState([]);
  const [friendRequests, setFriendRequests] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [usersList, setUsersList] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchFriendsList = async () => {
      try {
        const response = await fetch(`${API_URL}/user/${id}/friends`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('libriToken')}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch friends list');
        }

        const friendsData = await response.json();
        setFriends(friendsData);
      } catch (error) {
        console.error('Error fetching friends:', error);
      }
    };

    const fetchFriendRequests = async () => {
      try {
        const response = await fetch(`${API_URL}/user/${id}/friend-requests`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('libriToken')}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch friend requests');
        }

        const friendRequestsData = await response.json();
        setFriendRequests(friendRequestsData);
      } catch (error) {
        console.error('Error fetching friend requests:', error);
      }
    };

    fetchFriendsList();
    fetchFriendRequests();
  }, [id]);

  const handleFriendClick = (friendId) => {
    navigate(`/user/${id}/friend/${friendId}/public-collections`);
  };

  const searchUsers = useCallback(
    debounce(async () => {
      try {
        const response = await fetch(`${API_URL}/users?username=${searchQuery}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('libriToken')}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch users');
        }

        const usersData = await response.json();
        setUsersList(usersData);
      } catch (error) {
        console.error('Error searching users:', error);
        setError('Failed to fetch users');
      }
    }, 500), // 500ms delay
    [searchQuery]
  );

  useEffect(() => {
    if (searchQuery) {
      searchUsers();
    } else {
      setUsersList([]);
    }
  }, [searchQuery, searchUsers]);

  const sendFriendRequest = async (friendId) => {
    try {
      const response = await fetch(`${API_URL}/user/${id}/send-friend-request/${friendId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('libriToken')}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to send friend request');
      }

      setUsersList(usersList.filter(user => user._id !== friendId));
    } catch (error) {
      console.error('Error sending friend request:', error);
      setError('Failed to send friend request');
    }
  };

  const acceptFriendRequest = async (requestId) => {
    try {
      const response = await fetch(`${API_URL}/user/${id}/accept-friend-request/${requestId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('libriToken')}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to accept friend request');
      }

      const updatedFriendRequests = friendRequests.filter(request => request._id !== requestId);
      const acceptedFriend = friendRequests.find(request => request._id === requestId);
      setFriends([...friends, acceptedFriend]);
      setFriendRequests(updatedFriendRequests);
    } catch (error) {
      console.error('Error accepting friend request:', error);
      setError('Failed to accept friend request');
    }
  };

  const declineFriendRequest = async (requestId) => {
    try {
      const response = await fetch(`${API_URL}/user/${id}/decline-friend-request/${requestId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('libriToken')}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to decline friend request');
      }

      const updatedFriendRequests = friendRequests.filter(request => request._id !== requestId);
      setFriendRequests(updatedFriendRequests);
    } catch (error) {
      console.error('Error declining friend request:', error);
      setError('Failed to decline friend request');
    }
  };

  return (
    <div>
      <Header />
      <div className="container">
        <h2>Liste d'amis</h2>

        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Rechercher par username"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        {usersList.length > 0 && (
          <ul className="list-group mb-3">
            {usersList.map((user) => (
              <li key={user._id} className="list-group-item d-flex justify-content-between align-items-center">
                {user.username}
                <button
                  className="btn btn-success"
                  onClick={() => sendFriendRequest(user._id)}
                >
                  Envoyer une demande d'ami
                </button>
              </li>
            ))}
          </ul>
        )}

        <h3>Mes amis</h3>
        <ul className="list-group">
          {friends.map((friend) => (
            <li key={friend._id} className="list-group-item" onClick={() => handleFriendClick(friend._id)}>
              {friend.username}
            </li>
          ))}
        </ul>

        <h3>Demandes d'amis</h3>
        <ul className="list-group">
          {friendRequests.map((request) => (
            <li key={request._id} className="list-group-item d-flex justify-content-between align-items-center">
              {request.username}
              <button
                className="btn btn-primary"
                onClick={() => acceptFriendRequest(request._id)}
              >
                Accepter
              </button>
              <button
                  className="btn btn-danger"
                  onClick={() => declineFriendRequest(request._id)}
                >
                  Refuser
                </button>
            </li>
          ))}
        </ul>

        {error && <p className="text-danger mt-3">{error}</p>}
      </div>
      <Footer />
    </div>
  );
}

export default FriendsList;
