import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import './assets/style.scss';
const API_URL = process.env.REACT_APP_API_URL;

const BookList = () => {
  const { id } = useParams(); // Extract id from URL
  const [library, setLibrary] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [query, setQuery] = useState('');
  const [sortBy, setSortBy] = useState('title');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const navigate = useNavigate();
  const booksPerPage = 18;

  useEffect(() => {
    const fetchLibrary = async () => {
      try {
        const token = localStorage.getItem('libriToken'); // Ensure token is retrieved
        if (!token) {
          throw new Error('No token found');
        }

        const response = await fetch(`${API_URL}/user/${id}/library`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Failed to fetch library');
        }

        const data = await response.json();
        setLibrary(data);
        setTotalResults(data.length);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setError(err.message);
        setLoading(false);
        if (err.message === 'Access denied') {
          localStorage.removeItem('libriToken');
          navigate('/'); // Redirect to login or an appropriate page
        }
      }
    };

    fetchLibrary();
  }, [id, navigate]);

  const handleSortChange = (e) => {
    setSortBy(e.target.value);
  };

  const handleDelete = async (bookId) => {
    const token = localStorage.getItem('libriToken');
    if (!token) {
      throw new Error('Authentication token not found. Please log in.');
    }
  
    try {
      // Supprimer le livre de la bibliothèque
      const response = await fetch(`${API_URL}/user/${id}/library/${bookId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `Failed to delete book ${bookId}`);
      }
  
      // Supprimer le livre de toutes les collections de l'utilisateur
      const collectionsResponse = await fetch(`${API_URL}/user/${id}/collections`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (collectionsResponse.ok) {
        const collections = await collectionsResponse.json();
        await Promise.all(collections.map(async (collection) => {
          await fetch(`${API_URL}/user/${id}/collections/${collection._id}/books/${bookId}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          });
        }));
      }
  
      setLibrary(prevLibrary => prevLibrary.filter(book => book.bookId !== bookId));
      setTotalResults(prevTotal => prevTotal - 1);
      alert('Livre supprimé avec succès !');
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };  

  const limitCategories = (categories) => {
    if (!categories) return '';

    let charCount = 0;
    const limited = [];

    for (let category of categories) {
      if (charCount + category.length > 50) {
        limited.push('...');
        break;
      }
      limited.push(category);
      charCount += category.length + 2; // Adding 2 for the ", " separator
    }

    return limited.join(', ');
  };

  const limitTitle = (title, maxLength = 50) => {
    if (!title) return '';

    if (title.length <= maxLength) {
      return title;
    } else {
      return title.substring(0, maxLength) + '...';
    }
  };

  const filteredBooks = library.filter(book =>
    book.title.toLowerCase().includes(query.toLowerCase()) ||
    (book.authors && book.authors.join(', ').toLowerCase().includes(query.toLowerCase())) ||
    (book.categories && book.categories.join(', ').toLowerCase().includes(query.toLowerCase()))
  );

  const sortedBooks = filteredBooks.sort((a, b) => {
    if (sortBy === 'title') {
      return a.title.localeCompare(b.title);
    } else if (sortBy === 'author') {
      return (a.authors[0] || '').localeCompare(b.authors[0] || '');
    } else if (sortBy === 'dateAdded') {
      return new Date(b.dateAdded) - new Date(a.dateAdded);
    } else {
      return 0;
    }
  });

  const startIndex = (currentPage - 1) * booksPerPage;
  const currentBooks = sortedBooks.slice(startIndex, startIndex + booksPerPage);

  const totalPages = Math.ceil(totalResults / booksPerPage);

  const Pagination = () => (
    <div className="pagination-container d-flex justify-content-center mt-4">
      <ul className="pagination">
        {[...Array(totalPages).keys()].map(pageNumber => (
          <li
            key={pageNumber + 1}
            className={`page-item ${currentPage === pageNumber + 1 ? 'active' : ''}`}
          >
            <button
              className="page-link"
              onClick={() => setCurrentPage(pageNumber + 1)}
            >
              {pageNumber + 1}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="container book-list-container py-5">
      <h2 className="text-center mb-4">Ma librairie</h2>

      <div className="row mb-4">
        <div className="col-md-6 offset-md-3 mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Chercher dans votre librairie"
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
              setCurrentPage(1); // Reset to the first page on a new search
            }}
          />
        </div>
        <div className="col-md-6 offset-md-3">
          <select className="form-select" value={sortBy} onChange={handleSortChange}>
            <option value="title">Trier par titres</option>
            <option value="author">Trier par auteurs</option>
            <option value="dateAdded">Trier par ajout récent</option>
          </select>
        </div>
      </div>

      {currentBooks.length > 0 && <Pagination />}

      <div className="row row-cols-1 row-cols-md-3 g-4 justify-content-center">
        {currentBooks.map((book) => (
          <div key={book.bookId} className="col mb-4">
            <button
              className="delete-button"
              onClick={(e) => {
                e.stopPropagation(); // Prevent triggering the Link navigation
                handleDelete(book.bookId);
              }}
            >
              &times;
            </button>
            <Link to={`book/${book.bookId}`} className="text-decoration-none text-dark">
              <div className="card h-100 shadow-sm book-item">
                <div
                  className="bg-image"
                  style={{ backgroundImage: `url(${book.imageLinks.small})` }}
                ></div>
                <div className="card-body d-flex flex-column">
                  <h4 className="card-title">{limitTitle(book.title)}</h4>
                  <p className="card-text">{book.authors.join(', ')}</p>
                  <p className="card-text">
                    <small className="text-muted">{limitCategories(book.categories)}</small>
                  </p>
                  <p className="card-text">
                    <small className="text-muted">{book.publishedDate}</small>
                  </p>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>

      {currentBooks.length > 0 && <Pagination />}
    </div>
  );
};

export default BookList;
