import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/style.scss';
import '@fortawesome/fontawesome-free/css/all.min.css';

const defaultImageUrl = './assets/BfriYg0iOCs.jpg';
const API_URL = process.env.REACT_APP_API_URL;
const GOOGLE_BOOKS_API_KEY = 'AIzaSyBJJYjOSVrsZqaJuMYl6tYwYvDaKsUMQMM';

function BookSearch({ isInCollectionDetail }) {
  const [query, setQuery] = useState(localStorage.getItem('query') || '');
  const [books, setBooks] = useState(JSON.parse(localStorage.getItem('books')) || []);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [libraryBooks, setLibraryBooks] = useState([]);
  const [currentCollectionBooks, setCurrentCollectionBooks] = useState([]);
  const { id, collectionId } = useParams();

  useEffect(() => {
    const fetchLibraryBooks = async () => {
      const token = localStorage.getItem('libriToken');
      if (!token) return;

      try {
        const response = await fetch(`${API_URL}/user/${id}/library`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        if (!response.ok) throw new Error('Failed to fetch library books');
        const data = await response.json();
        setLibraryBooks(data);
      } catch (err) {
        console.error(err);
      }
    };

    const fetchCollectionBooks = async () => {
      const token = localStorage.getItem('libriToken');
      if (!token) return;

      try {
        const response = await fetch(`${API_URL}/user/${id}/collections/${collectionId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        if (!response.ok) throw new Error('Failed to fetch collection books');
        const data = await response.json();
        setCurrentCollectionBooks(data.books || []);
      } catch (err) {
        console.error(err);
      }
    };

    if (isInCollectionDetail) {
      fetchLibraryBooks();
      fetchCollectionBooks();
    }
  }, [id, collectionId, isInCollectionDetail]);

  const handleSearch = async (page = 1) => {
    setIsLoading(true);
    if (query.trim() === '') return;
    const startIndex = (page - 1) * 16;
    const apiUrl = `https://www.googleapis.com/books/v1/volumes?q=${encodeURIComponent(query)}&printType=books&startIndex=${startIndex}&maxResults=16&key=${GOOGLE_BOOKS_API_KEY}`;
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) throw new Error('Failed to fetch');

      const data = await response.json();
      setBooks(data.items || []);
      setTotalResults(data.totalItems > 80 ? 80 : data.totalItems); // Limit to 80 results
      setIsLoading(false);
      setError('');
      localStorage.setItem('query', query);
      localStorage.setItem('books', JSON.stringify(data.items || []));
      localStorage.setItem('currentPage', page);
    } catch (err) {
      setError('An error occurred while fetching data');
      setBooks([]);
      setIsLoading(false);
      localStorage.removeItem('query');
      localStorage.removeItem('books');
    }
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (query.trim() === '') {
        setBooks([]);
        localStorage.removeItem('query');
        localStorage.removeItem('books');
      } else {
        handleSearch(currentPage);
      }
    }, 500); // Delay of 500ms after the last keystroke to trigger search

    return () => clearTimeout(timerId); // Cleanup timer between keystrokes
  }, [query, currentPage]);

  const fetchBookDetails = async (bookId) => {
    try {
      const response = await fetch(`https://www.googleapis.com/books/v1/volumes/${bookId}?key=${GOOGLE_BOOKS_API_KEY}`);
      if (!response.ok) throw new Error('Failed to fetch book details');
      const data = await response.json();
      return data.volumeInfo;
    } catch (error) {
      console.error('Error fetching book details:', error);
      return null;
    }
  };

  const addToLibrary = async (book) => {
    const token = localStorage.getItem('libriToken');
    if (!token) {
      alert('Merci de vous connecter.');
      return;
    }

    try {
      const bookDetails = await fetchBookDetails(book.id);
      if (!bookDetails) throw new Error('Failed to fetch detailed book information');

      const response = await fetch(`${API_URL}/user/${id}/library`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          bookId: book.id,
          title: bookDetails.title,
          authors: bookDetails.authors,
          categories: bookDetails.categories,
          publishedDate: bookDetails.publishedDate,
          imageLinks: {
            smallThumbnail: bookDetails.imageLinks?.smallThumbnail ?? defaultImageUrl,
            thumbnail: bookDetails.imageLinks?.thumbnail ?? defaultImageUrl,
            small: bookDetails.imageLinks?.small ?? defaultImageUrl,
            medium: bookDetails.imageLinks?.medium ?? defaultImageUrl,
            large: bookDetails.imageLinks?.large ?? defaultImageUrl,
          },
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `Erreur lors de l'ajout dans la librairie`);
      }

      alert('Livre ajouté à votre librairie avec succès !');
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  const addToCollection = async (book) => {
    const token = localStorage.getItem('libriToken');
    if (!token) {
      alert('Merci de vous connecter.');
      return;
    }

    try {
      const bookDetails = await fetchBookDetails(book.id);
      if (!bookDetails) throw new Error('Failed to fetch detailed book information');

      const response = await fetch(`${API_URL}/user/${id}/collections/${collectionId}/books`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          bookId: book.id,
          title: bookDetails.title,
          authors: bookDetails.authors,
          categories: bookDetails.categories,
          publishedDate: bookDetails.publishedDate,
          imageLinks: {
            smallThumbnail: bookDetails.imageLinks?.smallThumbnail ?? defaultImageUrl,
            thumbnail: bookDetails.imageLinks?.thumbnail ?? defaultImageUrl,
            small: bookDetails.imageLinks?.small ?? defaultImageUrl,
            medium: bookDetails.imageLinks?.medium ?? defaultImageUrl,
            large: bookDetails.imageLinks?.large ?? defaultImageUrl,
          },
          note: bookDetails.collectionNote,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `Erreur lors de l'ajout à votre collection`);
      }

      alert('Ajout à votre collection avec succès !');
    } catch (err) {
      console.error(err);
      alert(err.message || `Erreur lors de l'ajout à votre collection`);
    }
  };

  const totalPages = Math.ceil(totalResults / 20);

  const Pagination = () => (
    <div className="pagination-container d-flex justify-content-center mt-4">
      <ul className="pagination">
        {[...Array(totalPages).keys()].map(pageNumber => (
          <li
            key={pageNumber + 1}
            className={`page-item ${currentPage === pageNumber + 1 ? 'active' : ''}`}
          >
            <button
              className="page-link"
              onClick={() => setCurrentPage(pageNumber + 1)}
            >
              {pageNumber + 1}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );

  const clearQuery = () => {
    setQuery('');
    setBooks([]);
    setCurrentPage(1);
    localStorage.removeItem('query');
    localStorage.removeItem('books');
  };

  const isBookInLibrary = (bookId) => libraryBooks.some(book => book.bookId === bookId);
  const isBookInCollection = (bookId) => currentCollectionBooks.some(book => book.bookId === bookId);

  const handleAddBook = async (book) => {
    if (isBookInLibrary(book.id) && !isBookInCollection(book.id)) {
      await addToCollection(book);
    } else if (!isBookInLibrary(book.id)) {
      await addToLibrary(book);
      await addToCollection(book);
    }
    window.location.reload(); // Rafraîchir la page après avoir ajouté le livre
  };

  return (
    <div className="container book-search-container">
      <div className="row mb-4">
        <div className="col-md-6 offset-md-3 mb-3 d-flex align-items-center position-relative">
          <div className="input-wrapper flex-grow-1 d-flex align-items-center">
            {query && (
              <i className="fas fa-times-circle position-absolute end-0 me-3" style={{ cursor: 'pointer' }} onClick={clearQuery}></i>
            )}
            <div className={`spinner-container ms-2 ${isLoading ? 'loading' : ''}`}>
              <div className="spinner-border spinner-border-sm text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
            <input
              type="text"
              className="form-control flex-grow-1"
              placeholder="Ajoutez de nouveaux livres à votre bibliothèque..."
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
                setCurrentPage(1); // Reset to the first page on a new search
              }}
            />
          </div>
        </div>
      </div>
      {books.length > 0 && <Pagination />}
      {error && <p className="text-danger text-center">{error}</p>}
      <div className="row">
        {books.map(book => (
          <div key={book.id} className="col-md-3 mb-3">
              {isInCollectionDetail && (
                <button
                  className={`btn mt-2 ${isBookInLibrary(book.id) && isBookInCollection(book.id) ? 'btn-secondary' : isBookInLibrary(book.id) ? 'btn-success' : 'btn-primary'}`}
                  onClick={() => handleAddBook(book)}
                  disabled={isBookInLibrary(book.id) && isBookInCollection(book.id)}
                >
                  {isBookInLibrary(book.id) && isBookInCollection(book.id) ? (
                    <span>Présent</span>
                  ) : isBookInLibrary(book.id) ? (
                    <span>Dans librairie</span>
                  ) : (
                    <span>Ajouter</span>
                  )}
                </button>
              )}
            <div className="card h-100 book-item">
              <Link to={`/user/${id}/book/${book.id}`} className="text-decoration-none text-dark">
                <div className="card-body">
                  <h5 className="card-title">{book.volumeInfo.title}</h5>
                  <p className="card-text">{book.volumeInfo.authors?.join(', ')}</p>
                  <p className="card-text"><small className="text-muted">{book.volumeInfo.publishedDate}</small></p>
                  {book.volumeInfo.imageLinks?.thumbnail && (
                    <img src={book.volumeInfo.imageLinks.thumbnail} alt={book.volumeInfo.title} className="img-fluid" />
                  )}
                </div>
              </Link>
            </div>
          </div>
        ))}
      </div>

      {books.length > 0 && <Pagination />}
    </div>
  );
}

export default BookSearch;
