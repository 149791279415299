import './assets/style.scss';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
const API_URL = process.env.REACT_APP_API_URL;

const LibraryActions = ({
  book,
  isInLibrary,
  note,
  setNote,
  addToLibrary,
  saveNote,
  handleDelete,
  collections,
  selectedSource,
  setSelectedSource,
  saveCollectionNote
}) => {
  const [collectionNote, setCollectionNote] = useState('');
  const { id } = useParams();

  useEffect(() => {
    fetchNoteFromDatabase(selectedSource);
  }, [selectedSource, collections, book.id]);

  const fetchNoteFromDatabase = async (source) => {
    if (source === 'library') {
      if (!isInLibrary) {
        setNote('');
        return;
      }

      // Fetch note from library
      const token = localStorage.getItem('libriToken');
      if (!token) {
        alert('Authentication token not found. Please log in.');
        return;
      }

      try {
        const response = await fetch(`${API_URL}/user/${id}/library/${book.id}/note`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          const errorMessage = await response.json();
          throw new Error(errorMessage.message || 'Failed to fetch note');
        }

        const data = await response.json();
        setNote(data.note || '');
      } catch (err) {
        console.error(err);
        setNote('');
      }
    } else {
      fetchCollectionNoteFromDatabase(source);
    }
  };

  const fetchCollectionNoteFromDatabase = async (collectionId) => {
    const collection = collections.find(col => col._id === collectionId);
    const isBookInCollection = collection?.books.some(b => b.bookId === book.id);

    if (!isBookInCollection) {
      setCollectionNote('');
      return;
    }

    const token = localStorage.getItem('libriToken');
    if (!token) {
      alert('Authentication token not found. Please log in.');
      return;
    }

    try {
      const response = await fetch(`${API_URL}/user/${id}/collections/${collectionId}/books/${book.id}/note`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorMessage = await response.json();
        throw new Error(errorMessage.message || 'Failed to fetch collection note');
      }

      const data = await response.json();
      setCollectionNote(data.note || '');
    } catch (err) {
      console.error(err);
      setCollectionNote('');
    }
  };

  const handleSourceChange = (e) => {
    const newSource = e.target.value;
    setSelectedSource(newSource);
    fetchNoteFromDatabase(newSource);
  };

  const handleSaveNote = async () => {
    if (selectedSource === 'library') {
      await saveNote(note); // Pass the current value of 'note' to saveNote
    } else {
      if (!selectedSource) {
        alert('Veuillez sélectionner une source valide.');
        return;
      }
      await saveCollectionNote(selectedSource, book.id, collectionNote);
    }
    fetchNoteFromDatabase(selectedSource); // Fetch the updated note from the database
  };

  const handleNoteChange = (e) => {
    if (selectedSource === 'library') {
      setNote(e.target.value); // Update 'note' state
    } else {
      setCollectionNote(e.target.value); // Update 'collectionNote' state
    }
  };

  return (
    <div className="notes-section">
      {!isInLibrary && (
        <button onClick={addToLibrary} className="btn btn-primary">
          Ajouter à la librairie
        </button>
      )}
      {isInLibrary && (
        <>
          <h3>Votre note</h3>
          <select onChange={handleSourceChange} value={selectedSource} className="form-control mb-2">
            <option value="library">Librairie</option>
            {collections.map(collection => {
                // Vérifiez si le livre est présent dans la collection
                const isBookInCollection = collection.books.some(b => b.bookId === book.id);

                // Si le livre est présent, affichez l'option
                if (isBookInCollection) {
                return (
                    <option key={collection._id} value={collection._id}>
                    {collection.name}
                    </option>
                );
                } else {
                // Sinon, ne retournez rien (ne pas afficher cette option)
                return null;
                }
            })}
          </select>
          {selectedSource === 'library' ? (
            <>
              <textarea
                value={note} // Use 'note' as the value of the textarea
                onChange={handleNoteChange}
                rows="10"
                cols="50"
                className="form-control"
              />
              <button onClick={handleSaveNote} className="btn btn-success mt-2">
                Sauvegarder la note
              </button>
            </>
          ) : (
            <>
              <textarea
                value={collectionNote} // Use 'collectionNote' as the value of the textarea
                onChange={handleNoteChange}
                rows="10"
                cols="50"
                className="form-control"
              />
              <button onClick={handleSaveNote} className="btn btn-success mt-2">
                Sauvegarder la note de la collection
              </button>
            </>
          )}
          <button onClick={() => handleDelete(book.id)} className="btn btn-danger mt-2">
            Supprimer de la bibliothèque
          </button>
        </>
      )}
    </div>
  );
};

export default LibraryActions;
