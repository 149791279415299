import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const ShareCollectionLink = () => {
  const { collectionId } = useParams();
  const [copySuccess, setCopySuccess] = useState('');

  const link = `${window.location.origin}/public-collections/${collectionId}`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(link).then(() => {
      setCopySuccess('Lien copié dans le presse-papiers!');
    }, (err) => {
      setCopySuccess('Échec de la copie du lien.');
      console.error('Échec de la copie du lien : ', err);
    });
  };

  return (
    <div className="share-collection-link">
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          value={link}
          readOnly
        />
        <button
          className="btn btn-outline-secondary"
          onClick={copyToClipboard}
        >
          Copier le lien
        </button>
      </div>
      {copySuccess && <div className="alert alert-info">{copySuccess}</div>}
    </div>
  );
};

export default ShareCollectionLink;
