import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../containers/Header';
import Footer from '../../containers/Footer';
import BookmarkedCollections from '../../components/BookmarkedCollections';
import CollectionCard from '../../components/CollectionCard';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/style.scss'; // Assurez-vous d'ajouter le fichier de style si nécessaire
const API_URL = process.env.REACT_APP_API_URL;

function CollectionsList() {
  const { id } = useParams();
  const [collections, setCollections] = useState([]);
  const [error, setError] = useState('');
  const [newCollectionName, setNewCollectionName] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCollections = async () => {
      const token = localStorage.getItem('libriToken');
      if (!token) {
        alert('Authentication token not found. Please log in.');
        return;
      }

      try {
        const response = await fetch(`${API_URL}/user/${id}/collections`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          const errorMessage = await response.json();
          throw new Error(`Failed to fetch collections: ${errorMessage.error.message}`);
        }

        const collectionsData = await response.json();
        setCollections(collectionsData);
      } catch (err) {
        console.error(err);
        setError(`Error fetching collections: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchCollections();
  }, [id]);

  const handleCreateCollection = async () => {
    const token = localStorage.getItem('libriToken');
    if (!token) {
      alert('Authentication token not found. Please log in.');
      return;
    }

    try {
      const response = await fetch(`${API_URL}/user/${id}/collections`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          name: newCollectionName,
          owner: id,
        }),
      });
      if (!response.ok) {
        const errorMessage = await response.json();
        throw new Error(`Failed to create new collection: ${errorMessage.error.message}`);
      }
      const newCollection = await response.json();
      setCollections([...collections, newCollection]);
      setNewCollectionName('');
      alert('New collection created successfully!');
    } catch (err) {
      console.error(err);
      alert(`Error creating new collection: ${err.message}`);
    }
  };

  const handleDeleteCollection = async (e, collectionId) => {
    e.stopPropagation(); // Empêche la propagation de l'événement de clic parent

    const token = localStorage.getItem('libriToken');
    if (!token) {
      alert('Authentication token not found. Please log in.');
      return;
    }

    try {
      const response = await fetch(`${API_URL}/user/${id}/collections/${collectionId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorMessage = await response.json();
        throw new Error(`Failed to delete collection: ${errorMessage.error.message}`);
      }

      // Filtrer les collections pour supprimer celle qui a été supprimée
      const updatedCollections = collections.filter((collection) => collection._id !== collectionId);
      setCollections(updatedCollections);
      alert('Collection deleted successfully!');
    } catch (err) {
      console.error(err);
      alert(`Error deleting collection: ${err.message}`);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <Header />
      <div className="collection-container">
        <h3>Collections</h3>
        <div className="container collections-list">
          {error && <p className="error">{error}</p>}
          <div className="row">
            {collections.map((collection) => (
              <CollectionCard
                key={collection._id}
                collection={collection}
                id={id}
                handleDeleteCollection={handleDeleteCollection}
              />
            ))}
          </div>
        </div>
        <div className="create-collection-form mt-5">
          <h3>Créer une nouvelle collection</h3>
          <input
            type="text"
            value={newCollectionName}
            onChange={(e) => setNewCollectionName(e.target.value)}
            className="form-control mb-3"
            placeholder="Nom de la nouvelle collection"
          />
          <button onClick={handleCreateCollection} className="btn btn-primary">
            Créer
          </button>
        </div>
        <BookmarkedCollections />
      </div>
      <Footer />
    </div>
  );
}

export default CollectionsList;
