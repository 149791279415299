import Header from "../../containers/Header";
import Footer from "../../containers/Footer";
import './assets/style.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

function Home() {
  return (
    <div className="main">
      <Header />
      <div className="landing-page">
        <div className="overlay"></div>
        <div className="content">
          <h1>Bienvenue sur votre bibliothèque en ligne !</h1>
          <p>
            Ici, vous pouvez facilement faire l'inventaire de tous vos livres chez vous, gratuitement. Mettez à jour et consultez votre collection pour vérifier si vous avez déjà un livre ou non.
          </p>
        </div>
        <div className="content-container">
          <div className="content1">
            <h2>Gérez vos livres</h2>
            <p>Ajoutez, modifiez et supprimez des livres de votre collection en toute simplicité.</p>
          </div>
          <div className="content2">
            <h2>Consultez votre collection</h2>
            <p>Accédez rapidement à la liste de vos livres et vérifiez si vous possédez déjà un exemplaire.</p>
          </div>
          <div className="content3">
            <h2>Partagez avec des amis</h2>
            <p>Partagez votre collection avec des amis et découvrez ce qu'ils lisent.</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
