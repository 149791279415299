import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import defaultImg from './assets/BfriYg0iOCs.jpg'; // Importation de l'image

const API_URL = 'https://www.googleapis.com/books/v1/volumes';
const API_KEY = 'AIzaSyBJJYjOSVrsZqaJuMYl6tYwYvDaKsUMQMM'; // Remplacez par votre clé API Google Books

const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) return text;
  return text.substring(0, maxLength) + '...';
};

const BookRecommendations = ({ bookId }) => {
  const [recommendedBooks, setRecommendedBooks] = useState([]);
  const [error, setError] = useState('');
  const { id } = useParams();

  useEffect(() => {
    const fetchBookDetails = async () => {
      try {
        const response = await fetch(`${API_URL}/${bookId}?key=${API_KEY}`);
        if (!response.ok) throw new Error('Failed to fetch book details');
        
        const bookData = await response.json();
        fetchRecommendations(bookData.volumeInfo);
      } catch (err) {
        setError(err.message);
      }
    };

    const fetchRecommendations = async (bookInfo) => {
      try {
        // Utiliser les catégories pour la recherche
        let query = '';
        if (bookInfo.categories) {
          query = bookInfo.categories.join(' OR ');
        } else if (bookInfo.authors) {  
          // Si aucune catégorie n'est disponible, utilisez le titre comme fallback
          query = bookInfo.authors;
        } 
        
        if  (bookInfo.title) {
            query = bookInfo.title;
        }

        // Ajouter des mots-clés de la description pour améliorer les recommandations
        if (bookInfo.description) {
          const keywords = extractKeywords(bookInfo.description);
          query += ` ${keywords.join(' ')}`;
        }

        const response = await fetch(`${API_URL}?q=${encodeURIComponent(query)}&maxResults=4&key=${API_KEY}`);
        if (!response.ok) throw new Error('Failed to fetch recommendations');

        const data = await response.json();
        // Filter out books with the same title
        const filteredBooks = data.items.filter(
          (book) => book.volumeInfo.title !== bookInfo.title
        );
        setRecommendedBooks(filteredBooks);
      } catch (err) {
        setError(err.message);
      }
    };

    const extractKeywords = (text) => {
      // Extraire des mots-clés simples de la description du livre
      return text.split(' ').slice(0, 10); // Par exemple, prendre les 10 premiers mots
    };

    fetchBookDetails();
  }, [bookId]);

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  return (
    <div className="book-recommendations">
      <br />
      <h3>Recommendations</h3>
      <div className="row">
        {recommendedBooks.map((book) => (
          <div key={book.id} className="col-md-3 mb-3">
            <div 
              className="card h-100" 
              onClick={() => window.location.href = `/user/${id}/book/${book.id}`}
              style={{ cursor: 'pointer' }}
            >
              <img
                src={book.volumeInfo.imageLinks?.thumbnail ?? defaultImg}
                className="card-img-top"
                alt={truncateText(book.volumeInfo.title, 30)}
              />
              <div className="card-body">
                <h5 className="card-title">{truncateText(book.volumeInfo.title, 50)}</h5>
                {book.volumeInfo.categories && (
                  <p className="card-text">{truncateText(book.volumeInfo.categories, 30)}</p>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BookRecommendations;
