import React, { useState } from 'react';

const Collections = ({
  collections,
  addToCollection,
  book,
  selectedCollectionSource,
  setCollectionSelectedSource,
  isCollaborator, // Nouvelle prop
  isOwner
}) => {
  const [collectionNote, setCollectionNote] = useState('');

  const handleSourceChange = (e) => {
    const source = e.target.value;
    setCollectionSelectedSource(source);
  
    // Réinitialiser la note de collection si la bibliothèque est sélectionnée
    if (source === 'library') {
      setCollectionNote('');
    } else {
      const selectedCollectionSource = collections.find(collection => collection._id === source);
      const bookInCollection = selectedCollectionSource ? selectedCollectionSource.books.find(b => b.bookId === book.id) : null;
      setCollectionNote(bookInCollection ? bookInCollection.note : '');
    }
  };
  
  const handleAddToCollection = async () => {
    try {
      // Vérifier si une collection est sélectionnée
      if (!selectedCollectionSource) {
        throw new Error('Merci de choisir une collection.');
      }
  
      // Vérifiez si le livre n'est PAS déjà présent dans la collection
      const collection = collections.find(collection => collection._id === selectedCollectionSource);

      if (!collection) {
        throw new Error('Collection non trouvée.');
      }

      // Vérifiez si le livre n'est PAS déjà présent dans la collection
      const isBookNotInCollection = !collection.books.some(b => b.bookId === book.id);
  
      if (!isBookNotInCollection) {
        throw new Error('Le livre est déjà présent dans cette collection.');
      }
  
      // Appel à la fonction d'ajout à la collection
      await addToCollection(book.id, selectedCollectionSource, book.volumeInfo, collectionNote);
  
      // Affichage d'un message de succès
    } catch (error) {
      console.error('Erreur lors de l\'ajout à la collection:', error.message);
      alert('Erreur lors de l\'ajout à la collection: ' + error.message);
    }
  };
  
  return (
<>
  <div className="add-to-collection mb-3">
      <div className="add-to-collection mb-3">
        <h3>Ajouter à votre collection</h3>
        <select className="form-select" value={selectedCollectionSource} onChange={handleSourceChange}>
          <option value="library"></option>
          {collections.map((collection) => {
            const isBookNotInCollection = !collection.books.some(b => b.bookId === book.id);
            if (isBookNotInCollection) {
              return (
                <option key={collection._id} value={collection._id}>
                  {isOwner ? `Créateur - ${collection.name}` : (isCollaborator ? `Collaboration - ${collection.name}` : collection.name)}
                </option>
              );
            } else {
              return null;
            }
          })}
        </select>
        <button onClick={handleAddToCollection} className="btn btn-primary mt-2">
          Ajouter à une collection
        </button>
      </div>
  </div>
</>

  );
};

export default Collections;
