import React, { useEffect, useState } from 'react';
import Header from '../../containers/Header';
import Footer from '../../containers/Footer';
import { useParams } from 'react-router-dom';
import CollectionCard from '../../components/CollectionCard';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/style.scss';
const API_URL = process.env.REACT_APP_API_URL;

function PublicCollections() {
  const { id, friendId } = useParams();
  const [collections, setCollections] = useState([]);
  const [username, setUsername] = useState(''); // Ajouter un état pour stocker le username
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchPublicCollections() {
      setLoading(true); // Commencer le chargement
      try {
        const response = await fetch(`${API_URL}/user/${id}/friend/${friendId}/public-collections`);
        if (!response.ok) {
          const errorMsg = await response.text(); // Utiliser response.text() pour récupérer le texte d'erreur
          throw new Error(`Failed to fetch collections: ${errorMsg}`);
        }
        const data = await response.json();
        setUsername(data.username); // Définir le username
        setCollections(data.collections); // Assurez-vous que vous traitez la bonne structure de données ici
      } catch (error) {
        console.error('Error fetching public collections:', error);
        setError(error.toString());
      } finally {
        setLoading(false); // Arrêter le chargement quel que soit le résultat
      }
    }

    fetchPublicCollections();
  }, [id, friendId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <Header />
      <div className="collection-container">
        <h3>Collections publique de {username}</h3>
        <div className="container collections-list">
          {error && <p className="error">{error}</p>}
          <div className="row">
            {collections.map((collection) => (
              <CollectionCard
                key={collection._id}
                collection={collection}
                id={id}
                friendId={friendId} // Pass friendId to the CollectionCard
                handleDeleteCollection={null} // Pas besoin de passer la fonction de suppression pour les collections publiques
              />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PublicCollections;
