import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../containers/Header';
import Footer from '../../containers/Footer';
import { useNavigate } from 'react-router-dom';
import defaultImageUrl from './assets/BfriYg0iOCs.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/style.scss';
import BookInfo from '../../components/BookInfo';
const API_URL = process.env.REACT_APP_API_URL;
const defaultImageAlt = 'Photo de Sincerely Media sur Unsplash';

function PublicBookDetails() {
  const navigate = useNavigate();
  const { id, friendId, collectionId, bookId } = useParams();
  const [book, setBook] = useState(null);
  const [note, setNote] = useState('');

  useEffect(() => {
    const fetchBookDetails = async () => {
      const API_KEY = 'AIzaSyBJJYjOSVrsZqaJuMYl6tYwYvDaKsUMQMM';
      const apiUrl = `https://www.googleapis.com/books/v1/volumes/${bookId}?key=${API_KEY}`;
      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          const errorMessage = await response.json();
          throw new Error(`Failed to fetch: ${errorMessage.error.message}`);
        }

        const data = await response.json();
        setBook(data);
      } catch (err) {
        console.error(err);
        setBook(null);
      }
    };

    const fetchPublicNote = async () => {
      try {
        const response = await fetch(`${API_URL}/user/${id}/friend/${friendId}/public-collections/${collectionId}/books/${bookId}/note`);
        if (!response.ok) {
          const errorMessage = await response.json();
          throw new Error(`Failed to fetch note: ${errorMessage.error.message}`);
        }

        const data = await response.json();
        setNote(data.note);
      } catch (err) {
        console.error(err);
        setNote('');
      }
    };

    fetchBookDetails();
    fetchPublicNote();
  }, [id, friendId, collectionId, bookId]);

  return (
    <div>
      <Header />
      <div className="container book-detail">
        <button className="back-to-menu" onClick={() => navigate(-1)}>
          &larr; Back to Menu
        </button>
        {!book ? (
          <p>Loading...</p>
        ) : (
          <div className="row">
            <div className="col-md-8">
              <BookInfo book={book} />
            </div>
            <div className="col-md-4">
              {book.volumeInfo.imageLinks?.small ? (
                <img src={book.volumeInfo.imageLinks.small} alt={book.volumeInfo.title} className="img-fluid mb-3" />
              ) : (
                <img src={defaultImageUrl} alt={defaultImageAlt} className="img-fluid mb-3" />
              )}
              <div className="note-section">
                <h5>Note</h5>
                <p>{note || "Pas de note... Pour l'instant."}</p>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default PublicBookDetails;
