import React from 'react';
import PropTypes from 'prop-types';
import './assets/style.scss'; // Assurez-vous de créer le fichier SCSS correspondant pour les styles

const CollectionStats = ({ likes, bookmarks, views }) => {
  return (
    <div className="collection-stats">
      <div className="stat-item">
        <span className="stat-label">J'aime</span>
        <span className="stat-value">❤️ {likes}</span>
      </div>
      <div className="stat-item">
        <span className="stat-label">Bookmarks</span>
        <span className="stat-value">🔖 {bookmarks}</span>
      </div>
      <div className="stat-item">
        <span className="stat-label">Vues</span>
        <span className="stat-value">{views}</span>
      </div>
    </div>
  );
};

CollectionStats.propTypes = {
  likes: PropTypes.number.isRequired,
  bookmarks: PropTypes.number.isRequired,
  views: PropTypes.number.isRequired,
};

export default CollectionStats;
