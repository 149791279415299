// src/containers/Header.js
import React, { useState, useEffect } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { useLocation, Link, useParams } from 'react-router-dom';
import { FaBook, FaSignInAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import ModalConnect from '../../components/ModalConnect';
import './assets/style.scss';

function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [isUserPage, setIsUserPage] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setIsUserPage(location.pathname.startsWith("/user"));
  }, [location.pathname]);

  useEffect(() => {
    function handleShowModal() {
      setShowModal(true);
    }
    document.addEventListener('showConnectionModal', handleShowModal);

    return () => {
      document.removeEventListener('showConnectionModal', handleShowModal);
    };
  }, []);

  useEffect(() => {
    function handleScroll() {
      const header = document.getElementById("header");
      const navBar = document.getElementById("navBar");
      if (!header) return;

      if (window.scrollY > 0) {
        header.classList.add("scrolled");
        navBar.classList.add("transparent");
      } else {
        header.classList.remove("scrolled");
        navBar.classList.remove("transparent");
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const logout = () => {
    localStorage.removeItem('libriToken');
    localStorage.removeItem('query');
    localStorage.removeItem('currentPage');
    localStorage.removeItem('books');
    navigate('/');
  };

  return (
    <header id="header" className="header sticky-top">
      <Navbar id="navBar" expand="lg" className="custom-navbar">
        <Navbar.Brand 
          as={Link} 
          to={isUserPage ? `/user/${id}` : '/'} 
          className={location.pathname === `/user/${id}` ? 'active' : ''}
        >
          <FaBook />Bibliothèque
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="linksButton">
            {isUserPage && (
              <>
                <Nav.Link as={Link} to={`/user/${id}/collections`} className={location.pathname === `/user/${id}/collections` ? 'active' : ''}>Collections</Nav.Link>
                <Nav.Link as={Link} to={`/user/${id}/friends`} className={location.pathname === `/user/${id}/friends` ? 'active' : ''}>Liste d'amis</Nav.Link>
              </>
            )}
          </Nav>
          <Nav className="conButton">
            {isUserPage ? (
              <>
                <Nav.Link as={Link} to={`/user/${id}/userprofil`} className={location.pathname === `/user/${id}/userprofil` ? 'active' : ''}>Info utilisateur</Nav.Link>
                <Nav.Link onClick={logout}>Déconnexion</Nav.Link>
              </>
            ) : (
              <Nav.Link onClick={() => setShowModal(true)}><FaSignInAlt /> Connexion</Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <ModalConnect showModal={showModal} setShowModal={setShowModal} />
    </header>
  );
}

export default Header;
