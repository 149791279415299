import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function Footer() {
  return (
    <footer className="bg-dark text-white text-center py-3">
      <div className="container">
        <a
          href="https://patreon.com/NexusLibri?utm_medium=unknown&utm_source=join_link&utm_campaign=creatorshare_creator&utm_content=copyLink"
          className="btn btn-primary"
          target="_blank"
          rel="noopener noreferrer"
        >
          Soutien sur Patreon ❤️😊
        </a>
      </div>
    </footer>
  );
}

export default Footer;
