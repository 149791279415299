import React, { useState, useEffect } from 'react';
import './assets/style.scss'; // Make sure to create this SCSS file

const API_URL = process.env.REACT_APP_API_URL;

const CollectionLikeBookView = ({ userId, friendId, collectionId }) => {
  const [likes, setLikes] = useState(0);
  const [bookmarks, setBookmarks] = useState(0);
  const [views, setViews] = useState(0);
  const [liked, setLiked] = useState(false);
  const [bookmarked, setBookmarked] = useState(false);

  useEffect(() => {
    const fetchCollectionStatus = async () => {
      try {
        const token = localStorage.getItem('libriToken');
        const headers = token ? {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        } : {
          'Content-Type': 'application/json'
        };

        const response = await fetch(`${API_URL}/public-collections/${collectionId}`, {
          method: 'GET',
          headers,
        });

        if (!response.ok) {
          const errorMessage = await response.json();
          throw new Error(`Failed to fetch collection status: ${errorMessage.error.message}`);
        }

        const data = await response.json();
        setLikes(data.likes);
        setBookmarks(data.bookmarks);
        setViews(data.views);
        setLiked(data.isLiked);
        setBookmarked(data.isBookmarked);
      } catch (err) {
        console.error(err);
        alert(`Error fetching collection status: ${err.message}`);
      }
    };

    fetchCollectionStatus();
  }, [collectionId]);

  const handleLike = async () => {
    const token = localStorage.getItem('libriToken');
    if (!token) {
      alert('Please log in to like this collection');
      return;
    }

    if (!liked) {
      try {
        const response = await fetch(`${API_URL}/user/${userId}/friend/${friendId}/public-collections/${collectionId}/like`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to like the collection');
        }

        const data = await response.json();
        setLikes(data.likes);
        setLiked(true);
      } catch (err) {
        console.error('Error liking the collection:', err);
      }
    }
  };

  const handleBookmark = async () => {
    const token = localStorage.getItem('libriToken');
    if (!token) {
      alert('Please log in to bookmark this collection');
      return;
    }

    if (!bookmarked) {
      try {
        const response = await fetch(`${API_URL}/user/${userId}/friend/${friendId}/public-collections/${collectionId}/bookmark`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to bookmark the collection');
        }

        const data = await response.json();
        setBookmarks(data.bookmarks);
        setBookmarked(true);
      } catch (err) {
        console.error('Error bookmarking the collection:', err);
      }
    }
  };

  return (
    <div className="collection-stats">
      <div className="stat-item" onClick={handleLike}>
        <span className="stat-value">{likes}</span>
        <span className="stat-label">{liked ? '❤️' : '🤍'}</span>
      </div>
      <div className="stat-item" onClick={handleBookmark}>
        <span className="stat-value">{bookmarks}</span>
        <span className="stat-label">{bookmarked ? '🔖' : '📑'}</span>
      </div>
      <div className="stat-item">
        <span className="stat-value">{views}</span>
        <span className="stat-label">Views</span>
      </div>
    </div>
  );
};

export default CollectionLikeBookView;