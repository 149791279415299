// src/containers/BookDetail.js
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../../containers/Header';
import Footer from '../../containers/Footer';
import AdsenseExample from '../../adsComponents/AdsenseExample';
import BookInfo from '../../components/BookInfoDetails';
import LibraryActions from '../../components/LibraryActions';
import Collections from '../../components/Collections';
import BookRecommendations from '../../components/BookRecommendations';

const defaultImageUrl = '.assets/BfriYg0iOCs.jpg'
const API_URL = process.env.REACT_APP_API_URL;

const BookDetail = () => {
  const { id, bookId } = useParams();
  const [book, setBook] = useState(null);
  const [note, setNote] = useState('');
  const [isInLibrary, setIsInLibrary] = useState(false);
  const [collections, setCollections] = useState([]);
  const [newCollectionName, setNewCollectionName] = useState('');
  const [selectedSource, setSelectedSource] = useState('library');
  const [selectedCollectionSource, setCollectionSelectedSource] = useState('library');
  const navigate = useNavigate();


  const addToLibrary = async () => {
    const token = localStorage.getItem('libriToken');
    if (!token) {
      alert('Merci de vous connecter.');
      return;
    }

    try {
      const response = await fetch(`${API_URL}/user/${id}/library`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          bookId: book.id,
          title: book.volumeInfo.title,
          authors: book.volumeInfo.authors,
          categories: book.volumeInfo.categories,
          publishedDate: book.volumeInfo.publishedDate,
          imageLinks: {
            smallThumbnail: book.volumeInfo.imageLinks?.smallThumbnail ?? defaultImageUrl,
            thumbnail: book.volumeInfo.imageLinks?.thumbnail ?? defaultImageUrl,
            small: book.volumeInfo.imageLinks?.small ?? defaultImageUrl,
            medium: book.volumeInfo.imageLinks?.medium ?? defaultImageUrl,
            large: book.volumeInfo.imageLinks?.large ?? defaultImageUrl,
          },
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `Erreur lors de l'/ajout dans la librairie`);
      }

      setIsInLibrary(true);
      alert('Livre ajouté à votre librairie avec succès !');
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  const saveNote = async () => {
    const token = localStorage.getItem('libriToken');
    if (!token) {
      alert('Merci de vous connecter.');
      return;
    }

    try {
      const response = await fetch(`${API_URL}/user/${id}/library/${bookId}/note`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ note }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `Erreur lors de l'enregistrement de la note`);
      }

      alert('Note sauvegardée avec succès !');
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  const handleDelete = async () => {
    const token = localStorage.getItem('libriToken');
    if (!token) {
      throw new Error('Merci de vous connecter.');
    }

    try {
      const response = await fetch(`${API_URL}/user/${id}/library/${bookId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        alert('Livre supprimé avec succès de votre librairie !');
        setIsInLibrary(false);
        setNote('');
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Erreur lors de la suppression de votre livre.');
      }
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  const addToCollection = async (userId, selectedCollectionSource, bookId, bookInfo, collectionNote) => {
    const token = localStorage.getItem('libriToken');
    if (!token) {
      alert('Merci de vous connecter.');
      return;
    }
  
    try {
      const response = await fetch(`${API_URL}/user/${userId}/collections/${selectedCollectionSource}/books`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          bookId: book.id,
          title: book.volumeInfo.title,
          authors: book.volumeInfo.authors,
          categories: book.volumeInfo.categories,
          publishedDate: book.volumeInfo.publishedDate,
          imageLinks: {
            smallThumbnail: book.volumeInfo.imageLinks?.smallThumbnail ?? defaultImageUrl,
            thumbnail: book.volumeInfo.imageLinks?.thumbnail ?? defaultImageUrl,
            small: book.volumeInfo.imageLinks?.small ?? defaultImageUrl,
            medium: book.volumeInfo.imageLinks?.medium ?? defaultImageUrl,
            large: book.volumeInfo.imageLinks?.large ?? defaultImageUrl,
          },
          note: book.volumeInfo.collectionNote,
        }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `Erreur lors de l'ajout à votre collection`);
      }
  
      alert('Ajout à votre collection avec succès !');
    } catch (err) {
      console.error(err);
      alert(err.message || `Erreur lors de l'ajout à votre collection`);
    }
  };

  const createCollection = async () => {
    const token = localStorage.getItem('libriToken');
    if (!token) {
      alert('Merci de vous connecter.');
      return;
    }

    try {
      const response = await fetch(`${API_URL}/user/${id}/collections`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ name: newCollectionName }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Erreur lors de la création de votre collection.');
      }

      const createdCollection = await response.json();
      setCollections([...collections, createdCollection]);
      setNewCollectionName('');
      alert('Collection créée avec succès !');
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };  

  const saveCollectionNote = async (collectionId, bookId, note) => {
    const token = localStorage.getItem('libriToken');
    if (!token) {
      alert('Merci de vous connecter.');
      return;
    }
  
    try {
      const response = await fetch(`${API_URL}/user/${id}/collections/${collectionId}/books/${bookId}/note`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ note }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Erreur lors de la sauvegarde de note de collection.');
      }
  
      alert('Note de collection enregistrée avec succès !');
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  return (
    <div>
      <Header />
      <div className="container book-detail">
        <button className="back-to-menu" onClick={() => navigate(-1)}>
          &larr; Retour en arrière
        </button>
          <BookInfo 
            id={id}
            bookId={bookId}
            setBook={setBook}
            setIsInLibrary={setIsInLibrary}
            setCollections={setCollections}
            setNote={setNote}
          />
        {book && (
          <div className="row">
            <div className="col-md-8">
            <AdsenseExample /> 
            <BookRecommendations 
            bookId={bookId} 
            />
            </div>
            <div className="col-md-4">
              {isInLibrary && (
                <Collections
                  collections={collections}
                  addToCollection={addToCollection}
                  createCollection={createCollection}
                  newCollectionName={newCollectionName}
                  setNewCollectionName={setNewCollectionName}
                  book={book}
                  bookId={bookId}
                  selectedCollectionSource={selectedCollectionSource}
                  setCollectionSelectedSource={setCollectionSelectedSource} // Assurez-vous de passer setSelectedSource ici également
                />
              )}
              <LibraryActions
                book={book}
                isInLibrary={isInLibrary}
                note={note}
                setNote={setNote}
                addToLibrary={addToLibrary}
                saveNote={saveNote}
                handleDelete={handleDelete}
                collections={collections}
                selectedSource={selectedSource}
                setSelectedSource={setSelectedSource} // Assurez-vous de passer setSelectedSource ici
                saveCollectionNote={saveCollectionNote}
              />
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default BookDetail;