import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from './pages/Home';
import BookDetail from './pages/BookDetail';
import CollectionsList from './pages/CollectionsList';
import CollectionDetails from './pages/CollectionDetails';
import PublicCollections from './pages/PublicCollections';
import PublicCollectionView from './containers/PublicCollectionView'; // Import the new component
import PublicCollectionDetails from './pages/PublicCollectionDetails';
import PublicBookDetails from './pages/PublicBookDetails';
import FriendsList from './pages/FriendList';
import UserLib from './pages/UserLib';
import UserProfile from './pages/UserProfile';
import reportWebVitals from './reportWebVitals';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/user/:id",
    element: <UserLib />, 
  },
  {
    path: "/user/:id/userprofil",
    element: <UserProfile />, 
  },
  {
    path: "/user/:id/book/:bookId",
    element: <BookDetail />, 
  },
  {
    path: "/user/:id/collections",
    element: <CollectionsList />, 
  },
  {
    path: "/user/:id/collections/:collectionId",
    element: <CollectionDetails />
  },
  {
    path: "/user/:id/friends",
    element: <FriendsList />,
  },
  {
    path:"/public-collections/:collectionId",
  element:<PublicCollectionView />,
  },
  {
    path: "/user/:id/friend/:friendId/public-collections",
    element: <PublicCollections />
  },
  {
    path: "/user/:id/friend/:friendId/public-collections/:collectionId",
    element: <PublicCollectionDetails />
  },
  {
    path: "/user/:id/friend/:friendId/public-collections/:collectionId/books/:bookId",
    element: <PublicBookDetails />
  }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

