// src/components/BookInfo.js
import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import defaultImageUrl from './assets/BfriYg0iOCs.jpg';
import './assets/style.scss';

const defaultImageAlt = 'Photo de Sincerely Media sur Unsplash';
const API_URL = process.env.REACT_APP_API_URL;

const BookInfoDetails = ({ id, bookId, setBook, setIsInLibrary, setCollections, setNote }) => {
  const [book, setBookState] = useState(null);

  useEffect(() => {
    const fetchBookDetails = async () => {
      const API_KEY = 'AIzaSyBJJYjOSVrsZqaJuMYl6tYwYvDaKsUMQMM';
      const apiUrl = `https://www.googleapis.com/books/v1/volumes/${bookId}?key=${API_KEY}`;
      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          const errorMessage = await response.json();
          throw new Error(`Failed to fetch: ${errorMessage.error.message}`);
        }

        const data = await response.json();
        setBookState(data);
        setBook(data);
      } catch (err) {
        console.error(err);
        setBookState(null);
        setBook(null);
      }
    };

    const fetchLibraryAndCollections = async () => {
      const token = localStorage.getItem('libriToken');
      if (!token) {
        return;
      }

      try {
        // Check if the book exists in the library
        const libraryResponse = await fetch(`${API_URL}/user/${id}/library`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (libraryResponse.ok) {
          const libraryData = await libraryResponse.json();
          const bookExists = libraryData.some(item => item.bookId === bookId);
          setIsInLibrary(bookExists);

          // Fetch note if the book exists in the library
          if (bookExists) {
            const noteResponse = await fetch(`${API_URL}/user/${id}/library/${bookId}/note`, {
              headers: {
                'Authorization': `Bearer ${token}`,
              },
            });

            if (noteResponse.ok) {
              const noteData = await noteResponse.json();
              setNote(noteData.note || '');
            }
          }
        } else {
          const errorMessage = await libraryResponse.json();
          throw new Error(`Failed to fetch library: ${errorMessage.error.message}`);
        }

        // Fetch user collections
        const collectionsResponse = await fetch(`${API_URL}/user/${id}/collections`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (collectionsResponse.ok) {
          const collectionsData = await collectionsResponse.json();
          setCollections(collectionsData);
        } else if (collectionsResponse.status === 404) {
          console.log('No collections found for the user.');
          setCollections([]); // Handle no collections gracefully
        } else {
          const errorMessage = await collectionsResponse.json();
          throw new Error(`Failed to fetch collections: ${errorMessage.error.message}`);
        }
      } catch (err) {
        console.error(err);
        alert(`Error fetching data: ${err.message}`);
      }
    };

    fetchBookDetails();
    fetchLibraryAndCollections();
  }, [id, bookId]);

  if (!book) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <div className="row">
        <div className="col-md-8">
          <h1>{book.volumeInfo.title}</h1>
          <p>{book.volumeInfo.authors?.join(', ')}</p>
          <div>{parse(book.volumeInfo.description || '')}</div>
        </div>
        <div className="col-md-4">
          {book.volumeInfo.imageLinks?.small ? (
            <img src={book.volumeInfo.imageLinks.small} alt={book.volumeInfo.title} className="img-fluid mb-3" />
          ) : (
            <img src={defaultImageUrl} alt={defaultImageAlt} className="img-fluid mb-3" />
          )}
        </div>
      </div>
    </div>
  );
};

export default BookInfoDetails;
